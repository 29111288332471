<template>
  <v-simple-table  class="margin-adjust v-data-table__survey">
    <template  v-slot:default>
      <thead class="mb-6">
        <tr class="blue-backgroun-list">
          <th class="subtitle-1">
            {{ title }} 
            ({{ answered }}/{{ total }})
            <v-icon v-if="answered == total">mdi-check-circle</v-icon>
          </th>
          <th class="subtitle-1 text-center" style="width: 48px;" v-if="display">Si</th>
          <th class="subtitle-1 text-center" style="width: 48px;" v-if="display">No</th>
          <th class="subtitle-1 text-center" style="width: 48px;">
            <v-btn icon @click="display = !display">
              <v-icon class="resize-icon" color="white">{{ display ? "mdi-menu-down" : "mdi-menu-right" }}</v-icon> 
            </v-btn>
          </th>
        </tr>
      </thead>

      <tbody v-show="display">
        <template  v-for="(item, index) in items">
          <tr :key="index">
            <td class="pt-4">
              <p>{{ item[itemQuestion] }}</p>
              <p class="primary--text">{{ comments[index] || "Sin comentarios." }}</p>
            </td>

            <td>
              <v-radio-group v-model="answers[index]" @change="handleItemClick(index)">
                <v-radio ref="radio-true" class="mx-0" value="true"/>
              </v-radio-group>
            </td>

            <td>
              <v-radio-group v-model="answers[index]" @change="handleItemClick(index)">
                <v-radio ref="radio-false" class="mx-0" value="false"/>
              </v-radio-group>
            </td>

            <td>
              <v-btn icon @click="selected = index" @click.passive="dialogs.comment = true">
                <v-icon color="primary" >mdi-chat-processing</v-icon>
              </v-btn>
            </td>
          </tr>         
        </template>
      </tbody>

      <v-dialog width="720" v-model="dialogs.comment">
        <v-card>
          <v-card-title class="pb-4">Agregar comentarios</v-card-title>
          <v-card-subtitle>{{items[selected][itemQuestion]}}</v-card-subtitle>
          <v-textarea class="px-4" label="Comentarios" outlined v-model="comments[selected]">Agregar comentarios</v-textarea>
          <v-card-actions class="justify-end">
            <v-btn color="primary" @click="handleItemComment" @click.passive="dialogs.comment = false">guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-simple-table>
</template>

<script>
  export default {
    computed: {

      /**
       * 
       */
      total() {
        return Object.values(this.items).length
      },
    },
    data: () => ({
      dialogs: {
        comment: false,
      },
      display: false,
      selected: 0,
      answered: 0,
      answereds: {

      },
      answers: {

      },
      comments: {

      },
    }),
    methods: {

      /**
       * 
       */
      handleItemClick(index) {
        this.answereds[index] = true

        this.calculateAnswered()

        //
        let { answers, comments, items } = this
        let itemQuestion  = items[index][this.itemQuestion]
        let itemAnswer    = answers[index] == "true"
        let itemComment   = comments[index]

        console.log(answers[index])

        //
        // console.log({ answers })
        
        //
        this.$emit('click-item', {
          question: itemQuestion,
          comment : itemComment,
          answer  : itemAnswer,
          answers : this.answers,
          index   : index,
        })
      },

      /**
       * 
       */
      handleItemComment() {
        this.$emit('item-comment', {
          comments: this.comments,
          index: this.selected,
        })
      },

      /**
       * 
       */
      calculateAnswered() {
        this.answered = Object.values(this.answereds).reduce((total, item) => {
          return item ? total + 1 : total
        }, 0)
      },

      /**
       * 
       */
      calculateAnswers() {
        Object.values(this.items).forEach((item, index) => {

          //
          let itemAnswered  = item.answered
          let itemAnswer    = item.yesOrNo
          let itemComment   = item.comment

          //
          if (itemAnswered) {
            this.answereds[index] = itemAnswered
            if (itemAnswer) {
              this.$refs['radio-true'][index].$el.click()
            } else {
              this.$refs['radio-false'][index].$el.click()
            }
          }
          
          //
          if (itemAnswered && itemComment) {
            this.comments[index] = itemComment
          }
        })
      },
    },
    mounted() {
      this.calculateAnswers()
      this.calculateAnswered()
    },
    props: {

      /**
       * 
       */
      itemAnswered: {
        type: String,
        default: 'answered',
      },

      /**
       * 
       */
      itemQuestion: String,

      /**
       * 
       */
      items: {
        type: [Array, Object],
        default: () => [],
      },

      /**
       * 
       */
      title: String,
    },
  }
</script>

<style>
  .margin-adjust{
    margin-left: 40px;
    margin-right: 40px;
  }
  .v-data-table__survey .v-input--selection-controls__input {
    margin: 0;
  }
  .theme--light.v-data-table table thead tr th {
    border-bottom: thin solid rgb(255, 255, 255) !important;
    font-size: 18px !important;
    font-family: 'CidereRegular' !important;
  }
  .theme--light.v-data-table table tbody tr td {
    border-bottom: thin solid rgba(0, 0, 0, 0.12) !important;
  }
    .theme--light.v-data-table table tbody tr td {
    background-color: #f8f4fc;


  }
  .blue-backgroun-list{
    background-color: #0033a0;
  }
  .blue-backgroun-list th{
    color: white !important;
  }
  .resize-icon{
    font-size: 60px !important;
  }
</style>